import React from 'react';
import classNames from 'classnames/bind';
import styles from './ContractDetailsForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import FormikField from 'common/components/forms/FormikField/FormikField';
import Input from 'common/components/Input/Input';
import getInitialValues from './get-initial-values';
import NumberInput from 'common/components/NumberInput/NumberInput';
import { UnitTypeEnum } from 'common/constants';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import prepareChanges from './prepare-changes';
import HiddenSubmitButtonForKeyboardEnter from 'common/components/HiddenEnterSubmitButton/HiddenSubmitButtonForKeyboardEnter';
import { logWarning } from 'common/utils/logger';
import DatePicker, {
    DatePickerOverlayPositionEnum,
    datePicketRangePresets,
} from 'design-system/components/date-pickers/DatePicker/DatePicker';
import RemoteFormActionsContext from 'common/contexts/remote-form-actions';
import values from 'lodash/values';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { PartnerContractDetailsT } from 'common/store/carrier-contract-details/models';
import { approveCarrierContract, updateCarrierContract } from 'common/store/carrier-contracts/actions';
import { selectUpdateCarrierContractRequest } from 'common/store/carrier-contracts/selectors';
import SideBarLoader from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLoader/SideBarLoader';
import { createUseWatchAnyFieldValueChanges } from 'common/utils/hooks/useWatchFormFieldChanges';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import ApproveContractConfirmation, {
    ConfirmApproveContractModalDataT,
} from 'common/layouts/BaseCarrierContractsLayout/dialogs/ApproveContractConfirmation/ApproveContractConfirmation';
import { selectCarrierContractStateById } from 'common/store/carrier-contract-details/selectors';

const cx = classNames.bind(styles);

type PropsT = {
    isReview: boolean;
    contractDetails: PartnerContractDetailsT | null;
    setNeedCloseConfirmation: (needCloseConfirmation: boolean) => void;
};

const ALL_FIELDS = values(FieldsEnum);
const useWatchAnyFieldValueChanges = createUseWatchAnyFieldValueChanges(ALL_FIELDS);

const ContractDetailsForm: React.FC<PropsT> = (props) => {
    const { isReview, contractDetails, setNeedCloseConfirmation } = props;

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const confirmApproveContractModalDialog = useModalDialog<ConfirmApproveContractModalDataT>();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(contractDetails);
        const errors = validateForm(t, values);
        return [values, errors];
    }, [contractDetails]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const contractId = contractDetails?.id || null;
            if (!contractId) {
                logWarning('empty contractId');
                return;
            }

            const contractChanges = prepareChanges(contractId, values);
            if (!contractChanges) {
                logWarning('empty contractChanges');
                return;
            }

            if (isReview) {
                confirmApproveContractModalDialog.setData({
                    contractId,
                    contractChanges,
                });
            } else {
                dispatch(
                    updateCarrierContract({
                        partnerId,
                        contractId,
                        contractChanges,
                    }),
                );
            }

            formikHelpers.setTouched({});
        },
    });

    const remoteFormActionsContext = React.useContext(RemoteFormActionsContext);
    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormCallbacks) {
            remoteFormActionsContext.setRemoteFormCallbacks({
                submit: formik.submitForm,
                reset: formik.resetForm,
            });
        }
    }, [formik.submitForm, formik.resetForm]);

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);
    const hasAnyFieldValueChanges = useWatchAnyFieldValueChanges(formik.values, initialValues);

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormState) {
            remoteFormActionsContext.setRemoteFormState({
                hasChanges: hasAnyFieldValueChanges,
                hasErrors: hasFormAnyErros,
            });
        }
    }, [hasFormAnyErros, hasAnyFieldValueChanges]);

    React.useEffect(() => {
        return () => {
            setNeedCloseConfirmation(false);
        };
    }, []);

    React.useEffect(() => {
        if (hasAnyFieldValueChanges) {
            setNeedCloseConfirmation(hasAnyFieldValueChanges);
        }
    }, [hasAnyFieldValueChanges]);

    const updateCarrierContractRequest = useSelector(selectUpdateCarrierContractRequest(partnerId));

    const isLoading = updateCarrierContractRequest.loading;

    React.useEffect(() => {
        if (remoteFormActionsContext.setRemoteFormRequest) {
            remoteFormActionsContext.setRemoteFormRequest({
                isLoading,
            });
        }
    }, [isLoading]);

    const handleConfirmApproveContract = (data: ConfirmApproveContractModalDataT) => {
        dispatch(
            approveCarrierContract({
                partnerId,
                contractId: data.contractId,
                contractChanges: data.contractChanges,
            }),
        );
    };

    const { fetchRequest: fetchContractDetailsRequest } = useSelector(
        selectCarrierContractStateById(contractDetails?.id),
    );

    if (isEmpty(contractDetails) && fetchContractDetailsRequest.loading) {
        return <SideBarLoader isRelative />;
    }

    return (
        <form onSubmit={formik.handleSubmit} className={cx('wrap')}>
            <FormikField
                className={cx('field', 'field--date')}
                name={FieldsEnum.expiration}
                error={formik.errors[FieldsEnum.expiration]}
                meta={formik.getFieldMeta(FieldsEnum.expiration)}
                label={t('common:carrier-contracts.details.fields.expiration.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <DatePicker
                        {...datePicketRangePresets.documentValidTill}
                        hasYearMonthForm
                        value={formik.values[FieldsEnum.expiration]}
                        placeholder={t('common:carrier-contracts.details.fields.expiration.placeholder')}
                        onChange={props.onChange}
                        hasWarning={props.hasWarning}
                        hasError={props.hasError}
                        overlayPosition={DatePickerOverlayPositionEnum.bottomLeft}
                        hasClearControl
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--name')}
                name={FieldsEnum.name}
                error={formik.errors[FieldsEnum.name]}
                meta={formik.getFieldMeta(FieldsEnum.name)}
                label={t('common:carrier-contracts.details.fields.name.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.name}
                        value={formik.values[FieldsEnum.name]}
                        placeholder={t('common:carrier-contracts.details.fields.name.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--number')}
                name={FieldsEnum.number}
                error={formik.errors[FieldsEnum.number]}
                meta={formik.getFieldMeta(FieldsEnum.number)}
                label={t('common:carrier-contracts.details.fields.number.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <Input
                        name={FieldsEnum.number}
                        value={formik.values[FieldsEnum.number]}
                        placeholder={t('common:carrier-contracts.details.fields.number.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--rate')}
                name={FieldsEnum.truckRate}
                error={formik.errors[FieldsEnum.truckRate]}
                meta={formik.getFieldMeta(FieldsEnum.truckRate)}
                label={t('common:carrier-contracts.details.fields.truck-rate.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.truckRate}
                        unitType={UnitTypeEnum.euroAbbreviation}
                        value={formik.values[FieldsEnum.truckRate]}
                        placeholder={t('common:carrier-contracts.details.fields.truck-rate.placeholder')}
                        step={1}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--rate')}
                name={FieldsEnum.tiltRate}
                error={formik.errors[FieldsEnum.tiltRate]}
                meta={formik.getFieldMeta(FieldsEnum.tiltRate)}
                label={t('common:carrier-contracts.details.fields.tilt-rate.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.tiltRate}
                        unitType={UnitTypeEnum.euroAbbreviation}
                        value={formik.values[FieldsEnum.tiltRate]}
                        placeholder={t('common:carrier-contracts.details.fields.tilt-rate.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--rate')}
                name={FieldsEnum.boxRate}
                error={formik.errors[FieldsEnum.boxRate]}
                meta={formik.getFieldMeta(FieldsEnum.boxRate)}
                label={t('common:carrier-contracts.details.fields.box-rate.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.boxRate}
                        unitType={UnitTypeEnum.euroAbbreviation}
                        value={formik.values[FieldsEnum.boxRate]}
                        placeholder={t('common:carrier-contracts.details.fields.box-rate.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--truck-expected-mileage')}
                name={FieldsEnum.truckExpectedMileage}
                error={formik.errors[FieldsEnum.truckExpectedMileage]}
                meta={formik.getFieldMeta(FieldsEnum.truckExpectedMileage)}
                label={t('common:carrier-contracts.details.fields.truck-expected-mileage.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.truckExpectedMileage}
                        unitType={UnitTypeEnum.kilometersAbbreviation}
                        value={formik.values[FieldsEnum.truckExpectedMileage]}
                        placeholder={t('common:carrier-contracts.details.fields.truck-expected-mileage.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--box-expected-mileage')}
                name={FieldsEnum.boxExpectedMileage}
                error={formik.errors[FieldsEnum.boxExpectedMileage]}
                meta={formik.getFieldMeta(FieldsEnum.boxExpectedMileage)}
                label={t('common:carrier-contracts.details.fields.box-expected-mileage.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.boxExpectedMileage}
                        unitType={UnitTypeEnum.kilometersAbbreviation}
                        value={formik.values[FieldsEnum.boxExpectedMileage]}
                        placeholder={t('common:carrier-contracts.details.fields.box-expected-mileage.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--tilt-expected-mileage')}
                name={FieldsEnum.tiltExpectedMileage}
                error={formik.errors[FieldsEnum.tiltExpectedMileage]}
                meta={formik.getFieldMeta(FieldsEnum.tiltExpectedMileage)}
                label={t('common:carrier-contracts.details.fields.tilt-expected-mileage.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.tiltExpectedMileage}
                        unitType={UnitTypeEnum.kilometersAbbreviation}
                        value={formik.values[FieldsEnum.tiltExpectedMileage]}
                        placeholder={t('common:carrier-contracts.details.fields.tilt-expected-mileage.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <FormikField
                className={cx('field', 'field--deadhead-expected-mileage')}
                name={FieldsEnum.deadheadExpectedMileage}
                error={formik.errors[FieldsEnum.deadheadExpectedMileage]}
                meta={formik.getFieldMeta(FieldsEnum.deadheadExpectedMileage)}
                label={t('common:carrier-contracts.details.fields.deadhead-expected-mileage.label')}
                setFieldValue={formik.setFieldValue}
                setFieldTouched={formik.setFieldTouched}
            >
                {(props) => (
                    <NumberInput
                        name={FieldsEnum.deadheadExpectedMileage}
                        unitType={UnitTypeEnum.kilometersAbbreviation}
                        value={formik.values[FieldsEnum.deadheadExpectedMileage]}
                        placeholder={t('common:carrier-contracts.details.fields.deadhead-expected-mileage.placeholder')}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus}
                        hasError={props.hasError}
                        hasWarning={props.hasWarning}
                        step={1}
                    />
                )}
            </FormikField>
            <HiddenSubmitButtonForKeyboardEnter />
            <ApproveContractConfirmation
                name={formik.values[FieldsEnum.name]}
                data={confirmApproveContractModalDialog.data}
                onCancel={confirmApproveContractModalDialog.onCancel}
                onClose={confirmApproveContractModalDialog.onClose}
                onConfirm={handleConfirmApproveContract}
                isLoading={isLoading}
            />
        </form>
    );
};

export default ContractDetailsForm;
